/**
 * User Details
 */

import { UserOfflineEntity } from "../common/types/DB";
import { db } from "./db";

// Fetch the connection ID for the User
export const fetchConnectionId = (userId: string) => {
  return db.userValues.get(userId);
};

export const upsertUserDetails = (value: UserOfflineEntity) => {
  if (value) return db.userValues.put(value);
};
