import classNames from "classnames";
import { ChangeEvent, useEffect, useRef, useState, MouseEvent } from "react";
import { FieldValueMetadata } from "../../../../common/types/dashboard/DashboardUITypes";
import { upsertFieldValues } from "../../../../db/fieldDBAction";
import { useAppSelector } from "../../../../redux/hooks";
import { selectSelectedDocumentId } from "../../../../redux/reducers/dashboardSlice";
import "./EditableValue.scss";

function EditableValue(props: {
  field: FieldValueMetadata;
  isSelected: boolean;
}) {
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);

  const field = { ...props.field };
  const initialValue = field.value;
  const [isEditable, setIsEditable] = useState(false);
  const [fieldNewValue, setFieldNewValue] = useState(field.value);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const setEditable = () => {
    setIsEditable(true);
  };
  const setNonEditable = () => {
    setIsEditable(false);
  };

  const handleChange = async (event: ChangeEvent<HTMLTextAreaElement>) => {
    // const newValue =
    //   event.target.textContent == null ? "" : event.target.textContent;
    // if (initialValue != newValue) {
    //   await upsertFieldValues({
    //     docId: selectedDocumentId,
    //     isPushed: false,
    //     fieldId: field.field,
    //     newValue: newValue,
    //     oldValue: field.value,
    //     isPersisted: false,
    //   });
    //   setFieldNewValue(newValue);
    //   field.prevValue = initialValue;
    //   field.value = newValue;
    // }

    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;

    const newValue = event.target.value;
    if (field.value != newValue) {
      await upsertFieldValues({
        docId: selectedDocumentId,
        isPushed: false,
        fieldId: field.field,
        newValue: newValue,
        oldValue: field.value,
        isPersisted: false,
      });
      setFieldNewValue(newValue);
      field.prevValue = initialValue;
      field.value = newValue;
    }
  };

  const handleDocumentClick: EventListener = async (event) => {
    if (
      textareaRef.current &&
      event.target instanceof Node &&
      !textareaRef.current.contains(event.target)
    ) {
      // Set the textarea to non-editable when clicked outside
      setIsEditable(false);
      if (initialValue != fieldNewValue) {
        await upsertFieldValues({
          docId: selectedDocumentId,
          isPushed: false,
          fieldId: field.field,
          newValue: fieldNewValue,
          oldValue: field.value,
          isPersisted: false,
        });

        field.prevValue = field.value;
        field.value = fieldNewValue;
      }
    }
  };

  useEffect(() => {
    // Add a document click event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Remove the document click event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <>
      <div
        style={{ cursor: "pointer", height: "auto" }}
        className={classNames("value", {
          hide: isEditable,
          "value-edited": initialValue != fieldNewValue,
          "value-selected": props.isSelected,
          "value-not-selected": !props.isSelected,
        })}
        onDoubleClick={setEditable}
      >
        {fieldNewValue}
      </div>
      <textarea
        ref={textareaRef}
        className={classNames("value", {
          hide: !isEditable,
        })}
        onChange={(event) => handleChange(event)}
        onBlur={setNonEditable}
        value={fieldNewValue}
      ></textarea>
      {/* {field.value.length > 50 ? (
        <textarea
          className={classNames("value", {
            hide: !isEditable,
          })}
          rows={5}
          onChange={(event) => handleChange(event)}
          onBlur={setNonEditable}
          value={fieldNewValue}
        ></textarea>
      ) : (
        <input
          onBlur={setNonEditable}
          type="text"
          value={fieldNewValue}
          onChange={(event) => handleChange(event)}
          className={classNames("value", {
            hide: !isEditable,
          })}
        ></input>
      )} */}
    </>
  );
}
export default EditableValue;
