import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  ABORT_DOCUMENT_UPLOAD,
  API_VARIABLE_SELECTED_DOCUMENT_ID,
  API_VARIABLE_SELECTED_LEASE_STATUS,
  DOCUMENTS_PROCESS_STATUS,
  REMOVE_DOCUMENT,
  UPDATE_DOCUMENT_LEASE_STATUS,
} from "../../api/api-constants";
import { AXIOS } from "../../api/axios";
import { DELETE_ACTION_DOC_IDS } from "../../common/constants";
import { UploadComponentInitializationError } from "../../common/errors";
import { mapDocumentStatusStateToOfflineEntity } from "../../common/types/Mapper";
import { DocumentsStatusState } from "../../common/types/SliceTypes";
import {
  fetchOfflineDocumentsStatus,
  upsertDocumentsStatus,
} from "../../db/documentStatusDBAction";
import { RootState } from "../store";

export const getDocumentStatus = createAsyncThunk(
  "/documents",
  async (_, { getState }) => {
    try {
      const s = getState() as RootState;
      const response = await AXIOS.get(
        DOCUMENTS_PROCESS_STATUS + "/" + s.user.id
      );
      if (response.status == 200) return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const cancelDocumentProcess = createAsyncThunk(
  "/document/process/cancel",
  async (docId: string) => {
    try {
      await AXIOS.put(ABORT_DOCUMENT_UPLOAD + "/" + docId);
      return { id: docId };
    } catch (err) {
      console.error(err);
    }
  }
);

export const removeDocument = createAsyncThunk(
  "/document/remove",
  async (docId: string) => {
    try {
      await AXIOS.delete(REMOVE_DOCUMENT + "/" + docId);
      return { id: docId };
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateDocumentLeaseStatus = createAsyncThunk(
  "/document/lease/status",
  async (filterName: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const url = UPDATE_DOCUMENT_LEASE_STATUS.replace(
        API_VARIABLE_SELECTED_DOCUMENT_ID,
        state.dashboard.selectedDocument.id
      ).replace(API_VARIABLE_SELECTED_LEASE_STATUS, filterName);
      await AXIOS.post(url);
    } catch (err) {
      console.error(err);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "/document/delete/",
  async (documentId: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const url = REMOVE_DOCUMENT + "/" + documentId;
      await AXIOS.delete(url);
    } catch (err) {
      console.error(err);
    }
  }
);

const initialState: DocumentsStatusState[] = [];

export const documentsStatusSlice = createSlice({
  name: "documentsStatus",
  initialState: initialState,
  reducers: {
    updateDocumentsStatusDeleted: (state, action) => {
      const index = state.findIndex((s) => s.id == action.payload.documentId);

      if (index >= 0) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            isDeleted: action.payload.isDeleted,
            deleted: action.payload.isDeleted,
          },
          ...state.slice(index + 1),
        ];
      }
    },
    updateDocumentStatusForReadStatus: (state, action) => {
      const index = state.findIndex((s) => s.id == action.payload.documentId);

      if (index >= 0) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            read: action.payload.isRead,
          },
          ...state.slice(index + 1),
        ];
      }
    },
    updateDocumentPercentage: (state, action) => {
      const index = state.findIndex((s) => s.id == action.payload.documentId);

      if (index >= 0) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            percentageCompleted: action.payload.percentage,
          },
          ...state.slice(index + 1),
        ];
      }
    },
    updateDocumentStatus: (state, action) => {
      const index = state.findIndex((s) => s.id == action.payload.documentId);

      if (index >= 0) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            apStatus: action.payload.status,
          },
          ...state.slice(index + 1),
        ];
      }
    },
    addDocumentStatus: (state, action) => {
      return [...state, action.payload];
    },
    setDocumentStatus: (state, action) => {
      return [...action.payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDocumentStatus.fulfilled, (state, action) => {
        if (action.payload != null && action.payload != undefined) {
          let payload: DocumentsStatusState[] = action.payload.filter(
            (doc: DocumentsStatusState) => !doc.isDeleted
          );
          let docIds = localStorage.getItem(DELETE_ACTION_DOC_IDS);
          if (docIds != null) {
            const docIdList: string[] = JSON.parse(docIds);
            payload = payload.filter(
              (doc) => docIdList.find((docId) => docId == doc.id) == undefined
            );
          }

          if (state.length == 0) {
            if (payload) {
              upsertDocumentsStatus(
                payload.map((documentStatus: DocumentsStatusState) =>
                  mapDocumentStatusStateToOfflineEntity(documentStatus, true)
                )
              );
            }
            return payload;
          } else {
            let docStatus = [...state];
            let isUpdated = false;
            payload.forEach((doc) => {
              const index = _.findIndex(docStatus, ["id", doc.id]);
              if (index == -1) {
                docStatus = docStatus.concat(doc);
                isUpdated = true;
              } else {
                if (!_.isEqual(docStatus[index], doc)) {
                  docStatus[index] = doc;
                  isUpdated = true;
                }
              }
            });
            if (isUpdated) {
              upsertDocumentsStatus(
                docStatus.map((documentStatus: DocumentsStatusState) =>
                  mapDocumentStatusStateToOfflineEntity(documentStatus, true)
                )
              );
              return _.orderBy(docStatus, "lastActionAt", "desc");
            }
          }
        }
      })
      .addCase(getDocumentStatus.rejected, (state, action) => {
        fetchOfflineDocumentsStatus().then((documentStatus) => {
          if (documentStatus == undefined || documentStatus.length == 0) {
            throw new UploadComponentInitializationError(
              "Document Status not Available."
            );
          } else {
            return [...documentStatus];
          }
        });
      })
      .addMatcher(
        isAnyOf(cancelDocumentProcess.fulfilled, removeDocument.fulfilled),
        (state, action) => {
          return [...state].filter((doc) => doc.id !== action.payload?.id);
        }
      );
  },
});

export const {
  setDocumentStatus,
  addDocumentStatus,
  updateDocumentStatus,
  updateDocumentPercentage,
  updateDocumentStatusForReadStatus,
  updateDocumentsStatusDeleted,
} = documentsStatusSlice.actions;

// Fetch all the documents which have deleted status as false
export const selectDocumentsStatus = (state: RootState) =>
  state.documentsStatus.filter((doc) => !doc.isDeleted);

export default documentsStatusSlice.reducer;
