import Save from "../../../../assets/images/dialog/settings/SettingsSave.svg";
import {
  SETTINGS_TAB_HEADER_SUB_TEXT,
  SETTINGS_TAB_NAME,
} from "../../../../common/constants";
import { useAppSelector } from "../../../../redux/hooks";
import { selectMarkets } from "../../../../redux/reducers/metadataSlice";
import Delete from "../../../../assets/images/dialog/settings/Delete.svg";
import "./Market.scss";
import classNames from "classnames";
import { EditText } from "react-edit-text";

function MarketSettings() {
  const markets = useAppSelector(selectMarkets);
  return (
    <div className="flex-basis-column">
      <div className="flex market-header">
        <div className="settings-content-header-text-holder">
          <span className="settings-content-header-text">
            {SETTINGS_TAB_NAME.MARKET}
          </span>
        </div>
        <div className="settings-content-header-text-holder">
          <span className="settings-content-sub-header-text">
            {SETTINGS_TAB_HEADER_SUB_TEXT.MARKET}
          </span>
        </div>
      </div>
      <div
        className="flex-basis-column"
        style={{ justifyContent: "space-between" }}
      >
        <div className="grid-container market-grid">
          {markets.map((market) => {
            return (
              <div className="market-container">
                <EditText
                  name={"market-" + market.name}
                  className="market-name"
                  defaultValue={market.name}
                />
                {/* <span className="market-name">{market.name}</span> */}
                <img
                  className={classNames(
                    market.isCustom ? "visibilty-not-hiddent" : "visible-hidden"
                  )}
                  src={Delete}
                ></img>
              </div>
            );
          })}
        </div>
        <div id="new-market">
          <span className="market-creator-header-text">Add a New Market</span>
          <div className="flex" style={{ gap: "5px" }}>
            <input />
            <img src={Save}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketSettings;
