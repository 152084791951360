import { FileUploader } from "react-drag-drop-files";
import { SUPPORTED_FILE_FORMATS } from "../../common/constants";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setName } from "../../redux/reducers/documentSlice";
import {
  selectDashboardLogoMetadata,
  selectUserIconMetadata,
} from "../../redux/reducers/uiSlice";
import { uploadDocument } from "../../redux/thunks";
import "./header.scss";
import { getDocumentStatus } from "../../redux/reducers/documentsStatusSlice";
import { setUploader } from "../../redux/reducers/popupSlice";
import { useAuth0 } from "@auth0/auth0-react";

const Header = () => {
  const dispatch = useAppDispatch();
  const logoMetadata = useAppSelector(selectDashboardLogoMetadata);
  const userMetadata = useAppSelector(selectUserIconMetadata);
  const { user, logout } = useAuth0();

  const openPopup = () => {
    dispatch(setUploader());
    // dispatch(getDocumentStatus()).then((d) => {
    //   dispatch(setUploader());
    //   // dispatch(setOpen());
    // });
  };

  const logoutWithRedirect = () => {
    localStorage.removeItem("login");
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleChange = (file: File) => {
    if (file !== undefined) {
      dispatch(setName(file.name));
      dispatch(uploadDocument(file)).then((d) => {
        openPopup();
      });
    }
  };
  return (
    // <div className="flex-fit-basis">
    <div className="flex-fit-basis" style={{ justifyContent: "space-between" }}>
      <div
        // style={{
        //   width: logoMetadata.widthPX,
        //   height: "var(--custom-page-header-height)",
        // }}
        className="image-container"
      >
        <button
          className="flex flex-align-center header-button-v4 header-button-v4-text choose-button-text-size"
          onClick={openPopup}
        >
          Upload Status
        </button>
        <FileUploader
          classes="flex fit-container"
          types={SUPPORTED_FILE_FORMATS}
          handleChange={handleChange}
          name="file"
          maxSize={200}
          required={true}
          children={
            <div className="flex fit-container flex-align-center drag-continer-text drag-continer-text-size">
              <button className="header-button-v4 header-button-v4-text choose-button-text-size">
                Choose File
              </button>
              <label className="choose-button-text-size" htmlFor="fileUpload">
                or drag LOI into the Table
              </label>
            </div>
          }
        />
      </div>
      {/* className="image-container" */}
      <div style={{ display: "flex", alignItems: "center", marginLeft: "-7%" }}>
        <img
          width={logoMetadata.widthPX}
          height={logoMetadata.heightPX}
          src={logoMetadata.image}
        />
      </div>
      {/* d-actions-container */}
      <div className="actions-container">
        <div className="flex user-container">
          <div
            className="flex-basis-column"
          >
            <span className="user-details user-details-text-size">
              {/* {user?.name} */}
              Tripp Russ - 90Ten Company
            </span>
            <span className="user-details user-details-text-size author-tenant-color">
              Tenant Representation
            </span>
          </div>
          <img
            width={userMetadata.widthPX}
            height={userMetadata.heightPX}
            src={userMetadata.image}
          />
        </div>
        <div className="flex">
          {/* <button
            className="flex flex-align-center button-orange action-buttons upload-button-size upload-button-text-size"
            onClick={logoutWithRedirect}
          >
            Logout
          </button> */}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Header;
