import { createSlice } from "@reduxjs/toolkit";
import { RESOLUTION_TYPE_DIMENSION } from "../../common/constants";
import { UIState } from "../../common/types/SliceTypes";
import { RootState } from "../store";

const initial: UIState = {
  metadata: RESOLUTION_TYPE_DIMENSION.HD,
  userFontSize: RESOLUTION_TYPE_DIMENSION.HD.fontSize,
  fontChangePer: 0,
  currentWidth: RESOLUTION_TYPE_DIMENSION.HD.width,
  currentHeight: RESOLUTION_TYPE_DIMENSION.HD.height,
  cellHeight: 0,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initial,
  reducers: {
    setResolutionType: (state, value) => {
      return {
        ...state,
        metadata: value.payload,
      };
    },
    setCurrentWidth: (state, value) => {
      return {
        ...state,
        currentWidth: value.payload,
      };
    },
    setCurrentHeight: (state, value) => {
      return {
        ...state,
        currentHeight: value.payload,
      };
    },
    setFontSize: (state, value) => {
      return {
        ...state,
        userFontSize: value.payload,
      };
    },
    setFontPer: (state, value) => {
      return {
        ...state,
        fontChangePer: value.payload,
      };
    },
    setCellHeight: (state, value) => {
      state.cellHeight = value.payload;
    },
  },
});

export const {
  setResolutionType,
  setCurrentHeight,
  setCurrentWidth,
  setFontSize,
  setFontPer,
  setCellHeight,
} = uiSlice.actions;

export const selectResolutionMetadata = (state: RootState) => state.ui.metadata;

export const selectCurrentHeight = (state: RootState) => state.ui.currentHeight;

export const selectCurrentWidth = (state: RootState) => state.ui.currentWidth;

export const selectFontSize = (state: RootState) => state.ui.userFontSize;

export const selectFontPer = (state: RootState) => state.ui.fontChangePer;

export const selectDashboardLogoMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.DASHBOARD_LOGO;

export const selectUploadDialogLogoMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.UPLOAD_DIALOG_LOGO;

export const selectUserIconMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.USER;

export const selectTabActionMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.TAB_ACTION;

export const selectTabAddMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.TAB_ADD;

export const selectTabViewMetadata = (state: RootState) =>
  state.ui.metadata.image_dimension.TABS_VIEW;

export const selectCellHeight = (state: RootState) => state.ui.cellHeight;

export const selectUnderHeaderHeight = (state: RootState) =>
  state.ui.metadata.underHeaderHeight;

export const selectLineHeight = (state: RootState) =>
  state.ui.metadata.headerLineHeight;

export const selectPadding = (state: RootState) => state.ui.metadata.padding;

export default uiSlice.reducer;
