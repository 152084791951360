import { createSlice } from "@reduxjs/toolkit";
import { userState } from "../../common/types/SliceTypes";
import { RootState } from "../store";

const initialState: userState = {
  id: process.env.REACT_APP_USER_EXTERNAL_ID
    ? process.env.REACT_APP_USER_EXTERNAL_ID
    : "1",
  name: "root",
  role: "root",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
});

export const {} = userSlice.actions;

export const selectUserID = (state: RootState) => state.user.id;
export const selectUserName = (state: RootState) => state.user.name;
export const selectUserRole = (state: RootState) => state.user.role;

export default userSlice.reducer;
