import _ from "lodash";
import toPX from "to-px";
import { CUSTOM_COLUMN_KEY } from "../../../common/constants";
import { getDimensionForResolution } from "../../../common/util";
import { VType } from "../../../redux/reducers/documentsSlice";
import { DocumentFieldValuesEntity } from "../../../common/types/EntityTypes";

export function getCellWidth(
  width: number,
  height: number,
  columnKey: String,
  fontChangePer: number = 0,
  isRem: boolean = false
) {
  // Column Specific Width
  function columnKeyWidth(
    resTypeDimension: any,
    key: String,
    isRem: boolean = false
  ) {
    switch (key) {
      case CUSTOM_COLUMN_KEY.PIN:
        return isRem
          ? resTypeDimension.columns_dimension.PIN.width
          : resTypeDimension.columns_dimension.PIN.widthPX;
      case CUSTOM_COLUMN_KEY.AUTHOR:
        return isRem
          ? resTypeDimension.columns_dimension.AUTHOR.width
          : resTypeDimension.columns_dimension.AUTHOR.widthPX;
      case CUSTOM_COLUMN_KEY.STATUS:
        return isRem
          ? resTypeDimension.columns_dimension.STATUS.width
          : resTypeDimension.columns_dimension.STATUS.widthPX;
      case CUSTOM_COLUMN_KEY.MARKET:
        return isRem
          ? resTypeDimension.columns_dimension.MARKET.width
          : resTypeDimension.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES:
        return isRem
          ? resTypeDimension.columns_dimension.NOTES.width
          : resTypeDimension.columns_dimension.NOTES.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES_HIDDEN:
        return isRem
          ? resTypeDimension.columns_dimension.NOTES_HIDDEN.width
          : resTypeDimension.columns_dimension.NOTES_HIDDEN.widthPX;
      case CUSTOM_COLUMN_KEY.CHILDREN:
        return isRem
          ? resTypeDimension.columns_dimension.CHILDREN.width
          : resTypeDimension.columns_dimension.CHILDREN.widthPX;
      default:
        return isRem
          ? resTypeDimension.columns_dimension.OTHERS.width
          : resTypeDimension.columns_dimension.OTHERS.widthPX;
    }
  }

  const resDimensionType = getDimensionForResolution(width, height);
  let desireWidth = columnKeyWidth(resDimensionType, columnKey);

  if (isRem) {
    desireWidth = toPX(desireWidth);
    if (desireWidth == null) {
      return 0;
    }
  }

  if (fontChangePer != null && fontChangePer > 0 && desireWidth != null) {
    desireWidth = desireWidth + desireWidth * fontChangePer;
  }
  return desireWidth;
}

export function getCellHeight(
  width: number,
  height: number,
  isRem: boolean = false
) {
  const resDimensionType = getDimensionForResolution(width, height);
  let calHeight = 0;
  if (isRem) {
    let desireHeight = resDimensionType.rowHeightREM;
    let px = toPX(desireHeight);
    calHeight = px == null ? 0 : px;
  } else {
    calHeight = resDimensionType.rowHeight;
  }

  return calHeight;
}

export function getBlankRowsValues(noOfHeaders: number) {
  return _.fill(Array(noOfHeaders), {
    id: -1,
    name: "",
    key: "",
    order: -1000,
    value: "",
    prevValue: "",
  } as VType);
}

export function getBlankDocumentData(noOfHeaders: number) {
  return _.fill(Array(noOfHeaders), {
    field: "",
    value: "",
  } as DocumentFieldValuesEntity);
}
