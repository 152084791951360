import {
  CellClassParams,
  ColDef,
  IRowNode,
  GetRowIdFunc,
  GetRowIdParams,
  ModelUpdatedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _, { uniqueId } from "lodash";
import { useCallback, useMemo, useState, useRef } from "react";
import Toggle from "react-toggle";
import {
  AP_STATUS,
  BACKEND_AP_STATUS,
  UPLOAD_HEADER,
} from "../../../common/constants";
import { ImageMetadata } from "../../../common/types/dashboard/DashboardUITypes";
import { DocumentsStatusState } from "../../../common/types/SliceTypes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectDocumentsStatus } from "../../../redux/reducers/documentsStatusSlice";
import { setClose } from "../../../redux/reducers/popupSlice";
import {
  selectResolutionMetadata,
  selectUploadDialogLogoMetadata,
} from "../../../redux/reducers/uiSlice";
import UploadTableCell from "./upload-table-cell/UploadTableCell";
import UploadTableHeader from "./upload-table-header/UploadTableHeader";
import "./upload.scss";
function Upload() {
  const dispatch = useAppDispatch();
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      margin: "var(--custom-upload-grid-margin)",
      marginRight: '10px',
      display: "flex",
      "flex-direction": "column",
      gap: "var(--custom-upload-dialog-header-content-gap)",
      overflow: "hidden",
    }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  let docsList = useAppSelector(selectDocumentsStatus);
  const resMetadata = useAppSelector(selectResolutionMetadata);
  const logo: ImageMetadata = useAppSelector(selectUploadDialogLogoMetadata);
  const gridRef = useRef<AgGridReact<DocumentsStatusState>>(null);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
    };
  }, []);

  const components = useMemo(
    () => ({
      agColumnHeader: UploadTableHeader,
    }),
    []
  );

  const [filters, setFilters] = useState([
    {
      name: AP_STATUS.DONE,
      text: _.capitalize(AP_STATUS.DONE),
      selected: true,
    },
    {
      name: AP_STATUS.IN_PROGRESS,
      text: _.startCase(_.toLower(_.replace(AP_STATUS.IN_PROGRESS, "_", " "))),
      selected: true,
    },
  ]);

  function getCellStyle(params: CellClassParams<any, any>, type: string) {
    if (params.data.percentageCompleted == 100) {
      if (!params.data.read) return { background: "rgba(250, 212, 127, 1)" };
      else return { background: "rgba(250, 212, 127, 1)" };
    } else {
      if (
        type == UPLOAD_HEADER.DOCUMENT_NAME &&
        params.data.percentageCompleted > 0
      ) {
        return {
          background:
            "linear-gradient(to right, rgba(250, 212, 127, 1) " +
            params.data.percentageCompleted +
            "%, white " +
            (params.data.percentageCompleted) +
            "%)",
        };
      }
      return null;
    }
  }

  function headersDef(): ColDef[] {
    let colDef: ColDef[] = [
      {
        colId: UPLOAD_HEADER.STATUS,
        headerComponentParams: { type: UPLOAD_HEADER.STATUS },
        cellRenderer: UploadTableCell,
        cellRendererParams: { type: UPLOAD_HEADER.STATUS },
        // flex: 0.6,
        cellStyle: (params) => getCellStyle(params, UPLOAD_HEADER.STATUS),
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const nodeAData = nodeA.data.apStatus;
          const nodeBData = nodeB.data.apStatus;
          if (nodeAData == nodeBData) return 0;
          return nodeAData > nodeBData ? 1 : -1;
        },
        width: resMetadata.columns_dimension.UPLOAD_STATUS.widthPX,
      },
      {
        colId: UPLOAD_HEADER.DOCUMENT_NAME,
        headerComponentParams: { type: UPLOAD_HEADER.DOCUMENT_NAME },
        cellRenderer: UploadTableCell,
        cellRendererParams: { type: UPLOAD_HEADER.DOCUMENT_NAME },
        // flex: 2,
        cellStyle: (params) =>
          getCellStyle(params, UPLOAD_HEADER.DOCUMENT_NAME),
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const nodeAData = nodeA.data.fileName;
          const nodeBData = nodeB.data.fileName;
          if (nodeAData == nodeBData) return 0;
          return nodeAData > nodeBData ? 1 : -1;
        },
        width: resMetadata.columns_dimension.UPLOAD_DOCUMENT_NAME.widthPX,
      },
      {
        colId: UPLOAD_HEADER.UPLOADED,
        headerComponentParams: { type: UPLOAD_HEADER.UPLOADED },
        cellRenderer: UploadTableCell,
        cellRendererParams: { type: UPLOAD_HEADER.UPLOADED },
        flex: 0.5,
        cellStyle: (params) => getCellStyle(params, UPLOAD_HEADER.UPLOADED),
        sort: "desc",
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          const nodeAData = new Date(nodeA.data.lastActionAt);
          const nodeBData = new Date(nodeB.data.lastActionAt);
          if (nodeAData == nodeBData) return 0;
          return nodeAData > nodeBData ? 1 : -1;
        },
        width: resMetadata.columns_dimension.UPLOAD_DOCUMENT_NAME.widthPX,
      },
    ];

    return colDef;
  }

  const toggleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterState = [...filters];
    filterState.forEach((filter) => {
      if (filter.name == e.currentTarget.value) {
        filter.selected = !filter.selected;
      }
    });
    setFilters(filterState);
    // updateFilteredDocs();
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<DocumentsStatusState>) => params.data.id;
  }, []);

  const filtersDiv = filters.map((filter, index) => {
    return (
      <div
        key={index}
        className="flex"
        style={{
          gap: "var(--custom-upload-dialog-header-filter-text-gap)",
          alignItems: "center",
          height: "var(--custom-upload-dialog-header-height)",
        }}
      >
        <span
          className="flex filter-text"
          style={{
            fontSize:
              "var(--custom-upload-dialog-header-filter-text-font-size)",
            height: "var(--custom-upload-dialog-header-height)",
          }}
        >
          {filter["text"]}
        </span>
        <label
          style={{
            width: "var(--custom-upload-dialog-header-filter-toggle-width)",
            height: "var(--custom-upload-dialog-header-height)",
            alignItems: 'center'
          }}
          className="flex"
        >
          <Toggle
            defaultChecked={true}
            value={filter["name"]}
            icons={false}
            onChange={toggleSelection}
          />
        </label>
      </div>
    );
  });

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<DocumentsStatusState>) => {
      if (node.data) {
        const status = node.data.apStatus;
        const appliedFilters = filters
          .filter((filter) => filter.selected)
          .map((filter) => filter.name);
        if (
          status == BACKEND_AP_STATUS.AP_COMPLETE.toString() &&
          appliedFilters.includes(AP_STATUS.DONE)
        ) {
          return true;
        } else if (
          [
            BACKEND_AP_STATUS.UPLOADING.toString(),
            BACKEND_AP_STATUS.ABSTRACTING.toString(),
            BACKEND_AP_STATUS.CONVERTING.toString(),
            BACKEND_AP_STATUS.CONVERTING.toString(),
            BACKEND_AP_STATUS.UPLOADED.toString(),
            BACKEND_AP_STATUS.HIGHLIGHTING.toString(),
          ].includes(status) &&
          appliedFilters.includes(AP_STATUS.IN_PROGRESS)
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    [filters]
  );

  return (
    <>
      <div style={containerStyle}>
        <div
          className="flex"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            height: "var(--custom-upload-dialog-header-height)",
          }}
        >
          <div
            className="flex"
            style={{ gap: "var(--custom-upload-dialog-header-filter-gap)" }}
          >
            {filtersDiv}
          </div>
          <img className="upload-logo" src={logo.image}></img>
        </div>
        <div
          id="upload-grid"
          style={{ height: "100%", boxSizing: "border-box" }}
        >
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact<DocumentsStatusState>
              ref={gridRef}
              rowData={docsList}
              getRowId={getRowId}
              columnDefs={headersDef()}
              defaultColDef={defaultColDef}
              components={components}
              headerHeight={resMetadata.uploaderHeaderRowHeight}
              rowHeight={resMetadata.uploaderRowHeight}
              isExternalFilterPresent={isExternalFilterPresent}
              doesExternalFilterPass={doesExternalFilterPass}
              animateRows={true}
              suppressRowClickSelection={true}
              suppressCellFocus={true}
              overlayNoRowsTemplate="No Documents Uploaded"
              overlayLoadingTemplate="No Documents Uploaded"
              onModelUpdated={(event: ModelUpdatedEvent) => {
                  event.api.getDisplayedRowCount() === 0 ? 
                    event.api.showNoRowsOverlay() : 
                    event.api.hideOverlay();
              }}
            ></AgGridReact>
          </div>
        </div>
        <div className="flex flex-align-end">
          <button
            style={{ justifyContent: "center" }}
            onClick={() => dispatch(setClose())}
            className="close-button close-btn-orange"
          >
            <span className="close-button-text">Close</span>
          </button>
        </div>
      </div>
    </>
  );
}
export default Upload;
