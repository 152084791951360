import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Wrapper from "./Wrapper";
import { useAxiosLoader } from "./api/axios";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { useEffect } from "react";

const App = () => {
  // const { isLoading, loginWithRedirect, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  // const [loading] = useAxiosLoader();

  // useEffect(() => {
  //   const userInfo = user?.email;
  //   if (!localStorage.getItem('login') && !userInfo && !isAuthenticated) {
  //     localStorage.setItem('login', 'true');
  //     loginWithRedirect();
  //   }

  //   if (isAuthenticated) {
  //     const getToken = async () => {
  //       try {
  //         const token = await getAccessTokenSilently();
  //         localStorage.setItem('token', token);
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     };
  //     getToken();
  //   }
  // }, [isAuthenticated]);

  // if (isLoading)
  //   return (
  //     <CircleSpinnerOverlay
  //       loading={loading}
  //       color='#404040'
  //       overlayColor='rgba(255,253,255,0.9)'
  //       zIndex={5000}
  //     />
  //   );
  return (
    // isAuthenticated &&
    <>
      {
        <div>
          <Wrapper />
        </div>
      }
    </>
  );
};

export default App;
