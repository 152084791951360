import Toggle from "react-toggle";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectLeaseStatus,
  toggleLeaseStatus,
} from "../../../redux/reducers/leaseStatusSlice";
import "./LeaseFilter.scss";
import { Item } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { LEASE_STATUS } from "../../../common/constants";
import classNames from "classnames";

function LeaseFilter() {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectLeaseStatus);
  const toggleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    filters.forEach((filter) => {
      if (filter.name == e.currentTarget.value) {
        dispatch(toggleLeaseStatus(filter.name));
      }
    });
  };

  const getCSSForLeaseStatus = (name: string) => {
    if (name == LEASE_STATUS.DEAD_DEAL) {
      return "dead-deal";
    } else if (name == LEASE_STATUS.IN_LEASE) {
      return "in-lease";
    } else {
      return "active-lease";
    }
  };

  const filterDivs = filters
    .filter(
      (filter) =>
        filter.name != LEASE_STATUS.NONE && filter.name != LEASE_STATUS.DUMMY
    )
    .map((filter, index) => {
      return (
        <Item
          style={{
            width: "100%",
            boxShadow: "0px 4px 7px 0px rgba(0, 0, 0, 0.25)",
          }}
          key={filter.name}
          id={filter.name}
          closeOnClick={false}
        >
          <div className="filter-div">
            <span
              style={{ width: "50%" }}
              className={classNames(
                "menu-status-text",
                getCSSForLeaseStatus(filter.name)
              )}
            >
              {filter.text}
            </span>
            <label style={{ width: "30%" }}>
              <Toggle
                defaultChecked={filter["selected"]}
                value={filter["name"]}
                icons={false}
                onChange={toggleSelection}
              />
            </label>
            <span style={{ width: "25%" }} className="menu-status-text">
              {filter["selected"] ? "Shown" : "Hidden"}
            </span>
          </div>
        </Item>
      );
    });
  return (
    <section>
      <div className="filter-header">
        <span className="filter-header-text">FILTER</span>
      </div>
      {filterDivs}
    </section>
  );
}

export default LeaseFilter;
