import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ABSTRACTED_DOCUMENT_CHILDREN_VALUES,
  ABSTRACTED_DOCUMENT_REVERT_VALUE,
  ABSTRACTED_DOCUMENT_UPDATE_VALUE,
  API_VARIABLE_CONNECTION_ID,
  API_VARIABLE_USER_ID,
  EVENT_SOURCE_OPEN_CONNECTION,
  UPDATE_ABSTRACTED_DOCUMENT_STATUS,
  UPDATE_DOCUMENT_NOTES,
  UPDATE_DOCUMENT_TRADE,
  UPLOAD_DOCUMENT,
} from "../api/api-constants";
import { AXIOS } from "../api/axios";
import {
  BACKEND_AP_STATUS,
  NOTIFICATION_COMPONENT_SUB_TYPE,
  NOTIFICATION_COMPONENT_TYPE,
  NOTIFICATION_TYPE,
} from "../common/constants";
import { ClientEntity, NotificationEntity } from "../common/types/EntityTypes";
import {
  FieldValue,
  NotesState,
  Status,
  TradeState,
} from "../common/types/dashboard/DashboardFunctionalTypes";
import { setBinding, setPing } from "./reducers/appSlice";
import { setID } from "./reducers/documentSlice";
import {
  addDocument,
  findClientIdForDocumentId,
  mapRowPin,
  markDocumentRead,
  updateAuthorForDocument,
  updateMarketForDocument,
  updateNotesForDocument,
  updateParentForDocument,
  updateStatusForDocument,
} from "./reducers/documentsDataSlice";
import { addChildrenColumnValues } from "./reducers/documentsSlice";
import {
  addDocumentStatus,
  updateDocumentPercentage,
  updateDocumentStatusForReadStatus,
  updateDocumentStatus,
} from "./reducers/documentsStatusSlice";
import { addNewClient } from "./reducers/metadataSlice";
import { RootState } from "./store";
import { mapClientEntityToOfflineClientEntity } from "../common/types/Mapper";
import { updateClient } from "../db/clientDBAction";

export const uploadDocument = createAsyncThunk(
  "/document/upload",
  async (file: File, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (file != undefined) {
        const data = new FormData();
        data.append("file", file);
        const response = await AXIOS.post(UPLOAD_DOCUMENT + s.user.id, data);
        if (response) {
          dispatch(setID(response.data));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const udpateLeaseStatus = createAsyncThunk(
  "/document/update/lease",
  async (status: Status, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (status != undefined) {
        const response = await AXIOS.post(
          UPDATE_ABSTRACTED_DOCUMENT_STATUS + status.id + "/" + status.status
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const setDocumentNotes = createAsyncThunk(
  "/document/update/notes",
  async (notes: NotesState, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (notes != undefined) {
        const response = await AXIOS.post(
          UPDATE_DOCUMENT_NOTES + notes.id,
          notes.notes,
          { headers: { "Content-Type": "application/json" } }
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const setDocumentTradeArea = createAsyncThunk(
  "/document/update/trade",
  async (trade: TradeState, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (trade != undefined) {
        const response = await AXIOS.post(
          UPDATE_DOCUMENT_TRADE + trade.id,
          trade.trade,
          { headers: { "Content-Type": "application/json" } }
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const documentChildrenValues = createAsyncThunk(
  "/document/children/values",
  async (parentID: string, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      const response = await AXIOS.get(
        ABSTRACTED_DOCUMENT_CHILDREN_VALUES.replace("?", parentID)
      );
      if (response.data?.length > 0) {
        dispatch(addChildrenColumnValues(response.data));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateDocumentFieldValue = createAsyncThunk(
  "/document/field/value",
  async (field: FieldValue, { getState, dispatch }) => {
    const s = getState() as RootState;
    let url = ABSTRACTED_DOCUMENT_UPDATE_VALUE;
    try {
      const response = await AXIOS.put(url, field);
      if (response.data) {
        // dispatch(setFieldValue(field));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const revertDocumentFieldValue = createAsyncThunk(
  "/document/field/value/revert",
  async (field: FieldValue, { getState, dispatch }) => {
    const s = getState() as RootState;
    let url = ABSTRACTED_DOCUMENT_REVERT_VALUE.replace(
      "?1",
      field.docId
    ).replace("?2", field.fieldCode);
    try {
      const response = await AXIOS.put(url);
      if (response.data) {
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const sse = createAsyncThunk(
  "/sse",
  async (connectionId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const url = EVENT_SOURCE_OPEN_CONNECTION.replace(
      API_VARIABLE_USER_ID,
      state.user.id
    ).replace(API_VARIABLE_CONNECTION_ID, connectionId);

    const eventHandler = new EventSource(url, { withCredentials: true });

    eventHandler.addEventListener("PING", (event: MessageEvent) => {
      dispatch(setPing());
    });

    eventHandler.addEventListener(NOTIFICATION_TYPE.ADD, (event) => {
      const notification: NotificationEntity = JSON.parse(event.data);

      switch (notification.type) {
        case NOTIFICATION_COMPONENT_TYPE.UPLOAD:
          switch (notification.subType) {
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT:
              dispatch(addDocumentStatus(notification.data));
              break;
          }
          break;
        case NOTIFICATION_COMPONENT_TYPE.TAB:
          const tab = {
            id: notification.data.externalId,
            isCustom: notification.data.custom,
            isDefault: notification.data.default,
            name: notification.data.name,
            viewOrder: notification.data.viewOrder,
            alias: notification.data.alias,
          } as ClientEntity;
          dispatch(addNewClient(tab));

          const offlineEntity = mapClientEntityToOfflineClientEntity(tab, true);
          updateClient(offlineEntity);
          break;
        case NOTIFICATION_COMPONENT_TYPE.DASHBOARD:
          switch (notification.subType) {
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT:
              dispatch(addDocument(notification.data));
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_PARENT:
              dispatch(updateParentForDocument(notification.data));
              break;
          }
          break;
      }
    });

    eventHandler.addEventListener(NOTIFICATION_TYPE.UPDATE, (event) => {
      const notification: NotificationEntity = JSON.parse(event.data);

      switch (notification.type) {
        case NOTIFICATION_COMPONENT_TYPE.UPLOAD:
          switch (notification.subType) {
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_AP_STATUS:
              dispatch(updateDocumentStatus(notification.data));
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_PERCENTAGE:
              if (notification.data.percentage == 100)
                dispatch(updateDocumentStatus(BACKEND_AP_STATUS.AP_COMPLETE));
              dispatch(updateDocumentPercentage(notification.data));
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUEMENT_READ_STATUS:
              dispatch(updateDocumentStatusForReadStatus(notification.data));
          }
          break;
        case NOTIFICATION_COMPONENT_TYPE.DASHBOARD:
          switch (notification.subType) {
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUEMENT_READ_STATUS:
              const clientForRead = findClientIdForDocumentId(state)(
                notification.data["documentId"]
              );
              if (clientForRead) {
                dispatch(
                  markDocumentRead({
                    clientId: clientForRead,
                    documentId: notification.data["documentId"],
                    status: notification.data["status"],
                  })
                );
              }
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_PINNED_STATUS:
              const clientForPinned = findClientIdForDocumentId(state)(
                notification.data["documentId"]
              );
              if (clientForPinned) {
                dispatch(
                  mapRowPin({
                    clientId: clientForPinned,
                    documentId: notification.data["documentId"],
                    status: notification.data["status"],
                  })
                );
              }
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_LEASE_STATUS:
              const clientForLeaseStatus = findClientIdForDocumentId(state)(
                notification.data["documentId"]
              );
              if (clientForLeaseStatus) {
                dispatch(
                  updateStatusForDocument({
                    clientId: clientForLeaseStatus,
                    documentId: notification.data["documentId"],
                    status: notification.data["status"],
                  })
                );
              }
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_NOTES:
              const clientForNotes = findClientIdForDocumentId(state)(
                notification.data["documentId"]
              );
              if (clientForNotes) {
                dispatch(
                  updateNotesForDocument({
                    clientId: clientForNotes,
                    documentId: notification.data["documentId"],
                    notes: notification.data["notes"],
                  })
                );
              }
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_MARKET:
              const clientForMarket = findClientIdForDocumentId(state)(
                notification.data["documentId"]
              );
              if (clientForMarket) {
                dispatch(
                  updateMarketForDocument({
                    clientId: clientForMarket,
                    documentId: notification.data["documentId"],
                    marketExternalId: notification.data["market"],
                  })
                );
              }
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_AUTHOR:
              const clientForAuthor = findClientIdForDocumentId(state)(
                notification.data["documentId"]
              );
              if (clientForAuthor) {
                dispatch(
                  updateAuthorForDocument({
                    clientId: clientForAuthor,
                    documentId: notification.data["documentId"],
                    marketExternalId: notification.data["author"],
                  })
                );
              }
              break;
          }
      }
    });

    eventHandler.onopen = () => dispatch(setBinding(true));
  }
);
